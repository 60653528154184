import { getData, setData } from '@slideslive/fuse-kit/utils';
import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.css';
import GlobalObserver from 'modules/global_observer';

function initChoices(element) {
  if (getData(element, 'choicesInitialized', false)) {
    return;
  }

  setData(element, 'choicesInitialized', true);

  let containerOuterClass = 'fieldBox';
  if (element.value && element.value !== '') {
    containerOuterClass += ' fieldBox--filled';
  }

  const choices = new Choices(element, {
    allowHTML: true,
    itemSelectText: '',
    searchResultLimit: 50,
    shouldSort: false,
    addItemText: '',
    classNames: {
      containerOuter: containerOuterClass,
    },
  });

  element.__choices = choices;

  if (element.dataset.initBlank === 'true') {
    choices.removeActiveItems();
  }

  if (element.readOnly) {
    choices.disable();
  }
}

function registerChoices() {
  GlobalObserver.register('select.newFieldChoices:not([data-auto-init="false"]):not(.choices__input)', (element) => {
    initChoices(element);
  });
}

export default registerChoices;
